<template>
  <!-- Topbar Start -->
  <div class="container-fluid bg-success px-5 d-none d-lg-block">
    <div class="row gx-0">
      <div class="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
        <div class="d-inline-flex align-items-center" style="height: 45px">
          <a
            class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
            href=""
            ><i class="fab fa-twitter fw-normal"></i
          ></a>
          <a
            class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
            href=""
            ><i class="fab fa-facebook-f fw-normal"></i
          ></a>
          <a
            class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
            href=""
            ><i class="fab fa-linkedin-in fw-normal"></i
          ></a>
          <a
            class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
            href=""
            ><i class="fab fa-instagram fw-normal"></i
          ></a>
          <a
            class="btn btn-sm btn-outline-light btn-sm-square rounded-circle"
            href=""
            ><i class="fab fa-youtube fw-normal"></i
          ></a>
        </div>
      </div>
      <!-- <div class="col-lg-4 text-center text-lg-end">
        <div class="d-inline-flex align-items-center" style="height: 45px">
          <a href="#"
            ><small class="me-3 text-light"
              ><i class="fa fa-user me-2"></i>Register</small
            ></a
          >
          <a href="#"
            ><small class="me-3 text-light"
              ><i class="fa fa-sign-in-alt me-2"></i>Login</small
            ></a
          >
          <div class="dropdown">
            <a
              href="#"
              class="dropdown-toggle text-light"
              data-bs-toggle="dropdown"
              ><small><i class="fa fa-home me-2"></i> My Dashboard</small></a
            >
            <div class="dropdown-menu rounded">
              <a href="#" class="dropdown-item"
                ><i class="fas fa-user-alt me-2"></i> My Profile</a
              >
              <a href="#" class="dropdown-item"
                ><i class="fas fa-comment-alt me-2"></i> Inbox</a
              >
              <a href="#" class="dropdown-item"
                ><i class="fas fa-bell me-2"></i> Notifications</a
              >
              <a href="#" class="dropdown-item"
                ><i class="fas fa-cog me-2"></i> Account Settings</a
              >
              <a href="#" class="dropdown-item"
                ><i class="fas fa-power-off me-2"></i> Log Out</a
              >
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <!-- Topbar End -->

  <!-- Navbar & Hero Start -->
  <div class="container-fluid position-relative p-0">
    <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
      <a href="/" class="navbar-brand p-0">
        <h1 class="m-0">
          <i class="fa fa-map-marker-alt me-3"></i>Classic Compass Tour
        </h1>
        <!-- <img src="img/logo.png" alt="Logo"> -->
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
      >
        <span class="fa fa-bars"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <div class="navbar-nav ms-auto py-0">
          <a href="/" class="nav-item nav-link active">Home</a>
          <a href="/about" class="nav-item nav-link">About</a>
          <a href="/services" class="nav-item nav-link">Services</a>
          <a href="/packages" class="nav-item nav-link">Packages</a>
          <a href="/blog" class="nav-item nav-link">Blog</a>
          <div class="nav-item dropdown">
            <a
              href="#"
              class="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              >Pages</a
            >
            <div class="dropdown-menu m-0">
              <a href="/destination" class="dropdown-item">Destination</a>
              <a href="/tour" class="dropdown-item">Explore Tour</a>
              <a href="/booking" class="dropdown-item">Travel Booking</a>
              <a href="/gallery" class="dropdown-item">Our Gallery</a>
              <a href="/guides" class="dropdown-item">Travel Guides</a>
            </div>
          </div>
          <a href="/contact" class="nav-item nav-link">Contact</a>
        </div>
        <a
          href="/booking"
          class="btn btn-success rounded-pill py-2 px-4 ms-lg-4"
          >Book Now</a
        >
      </div>
    </nav>

    <!-- Carousel Start -->
    <div class="carousel-header">
      <div id="carouselId" class="carousel slide" data-bs-ride="carousel">
        <ol class="carousel-indicators">
          <li
            data-bs-target="#carouselId"
            data-bs-slide-to="0"
            class="active"
          ></li>
          <li data-bs-target="#carouselId" data-bs-slide-to="1"></li>
          <li data-bs-target="#carouselId" data-bs-slide-to="2"></li>
        </ol>
        <div class="carousel-inner" role="listbox">
          <div class="carousel-item active">
            <img
              src="img/lake-kivu-shores.jpeg"
              class="img-fluid"
              alt="Image"
            />
            <div class="carousel-caption">
              <div class="p-3" style="max-width: 900px">
                <h4
                  class="text-white text-uppercase fw-bold mb-4"
                  style="letter-spacing: 3px"
                >
                  Classic Compass Tour
                </h4>
                <h1 class="display-2 text-capitalize text-white mb-4">
                  Let's Visit Rwanda Together!
                </h1>
                <p class="mb-5 fs-5">Where your next adventure awaits,</p>
                <div class="d-flex align-items-center justify-content-center">
                  <a
                    class="btn-hover-bg btn btn-success rounded-pill text-white py-3 px-5"
                    href="#"
                    >Discover Now</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <img
              src="img/Mountain-gorilla-lighter-900x600-1.jpeg"
              class="img-fluid"
              alt="Image"
            />
            <div class="carousel-caption">
              <div class="p-3" style="max-width: 900px">
                <h4
                  class="text-white text-uppercase fw-bold mb-4"
                  style="letter-spacing: 3px"
                >
                  Classic Compass Tour
                </h4>
                <h1 class="display-2 text-capitalize text-white mb-4">
                  You Like To Go?
                </h1>
                <p class="mb-5 fs-5">Unforgettable journeys begin here,</p>
                <div class="d-flex align-items-center justify-content-center">
                  <a
                    class="btn-hover-bg btn btn-success rounded-pill text-white py-3 px-5"
                    href="#"
                    >Discover Now</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <img
              src="img/Parc_National_d'Akagera.jpeg"
              class="img-fluid"
              alt="Image"
            />
            <div class="carousel-caption">
              <div class="p-3" style="max-width: 900px">
                <h4
                  class="text-white text-uppercase fw-bold mb-4"
                  style="letter-spacing: 3px"
                >
                  Classic Compass Tour
                </h4>
                <h1 class="display-2 text-capitalize text-white mb-4">
                  You Like To Go?
                </h1>
                <p class="mb-5 fs-5">Guiding you to timeless experiences.,</p>
                <div class="d-flex align-items-center justify-content-center">
                  <a
                    class="btn-hover-bg btn btn-success rounded-pill text-white py-3 px-5"
                    href="#"
                    >Discover Now</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselId"
          data-bs-slide="prev"
        >
          <span
            class="carousel-control-prev-icon btn bg-success"
            aria-hidden="false"
          ></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselId"
          data-bs-slide="next"
        >
          <span
            class="carousel-control-next-icon btn bg-success"
            aria-hidden="false"
          ></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
    <!-- Carousel End -->
  </div>
  <div
    class="container-fluid search-bar position-relative"
    style="top: -50%; transform: translateY(-50%)"
  >
    <div class="container">
      <div
        class="position-relative rounded-pill w-100 mx-auto p-5"
        style="background: rgba(19, 53, 123, 0.8)"
      >
        <input
          class="form-control border-0 rounded-pill w-100 py-3 ps-4 pe-5"
          type="text"
          placeholder="Eg: Musanze"
        />
        <button
          type="button"
          class="btn btn-success rounded-pill py-2 px-4 position-absolute me-2"
          style="top: 50%; right: 46px; transform: translateY(-50%)"
        >
          Search
        </button>
      </div>
    </div>
  </div>
  <!-- Navbar & Hero End -->

  <!-- About Start -->
  <div class="container-fluid about py-5">
    <div class="container py-5">
      <div class="row g-5 align-items-center">
        <div class="col-lg-5">
          <div
            class="h-100"
            style="
              border: 50px solid;
              border-color: transparent #13357b transparent #13357b;
            "
          >
            <img
              src="img/Mountain-gorilla-lighter-900x600-1.jpeg"
              class="img-fluid w-100 h-100"
              alt=""
            />
          </div>
        </div>
        <div
          class="col-lg-7"
          style="
            background: linear-gradient(
                rgba(255, 255, 255, 0.8),
                rgba(255, 255, 255, 0.8)
              ),
              url(img/about-img-1.png);
          "
        >
          <h5 class="section-about-title pe-3">About Us</h5>
          <h1 class="mb-4">
            Welcome to <span class="text-success">Classic Compass Tour</span>
          </h1>
          <p class="mb-4">
            we are passionate about crafting unforgettable travel experiences
            for discerning travelers, we curate itineraries that go beyond the
            ordinary, allowing you to truly connect with the destinations you
            visit.
          </p>
          <p class="mb-4">
            Our experienced guides are not just storytellers, but cultural ambassadors. They'll share their insights and ensure you have a safe and memorable journey.
          </p>
          <div class="row gy-2 gx-4 mb-4">
            <div class="col-sm-6">
              <p class="mb-0">
                <i class="fa fa-arrow-right text-success me-2"></i>First Class
                Flights
              </p>
            </div>
            <div class="col-sm-6">
              <p class="mb-0">
                <i class="fa fa-arrow-right text-success me-2"></i>Handpicked
                Hotels
              </p>
            </div>
            <div class="col-sm-6">
              <p class="mb-0">
                <i class="fa fa-arrow-right text-success me-2"></i>5 Star
                Accommodations
              </p>
            </div>
            <div class="col-sm-6">
              <p class="mb-0">
                <i class="fa fa-arrow-right text-success me-2"></i>Latest Model
                Vehicles
              </p>
            </div>
            <div class="col-sm-6">
              <p class="mb-0">
                <i class="fa fa-arrow-right text-success me-2"></i>150 Premium
                City Tours
              </p>
            </div>
            <div class="col-sm-6">
              <p class="mb-0">
                <i class="fa fa-arrow-right text-success me-2"></i>24/7 Service
              </p>
            </div>
          </div>
          <a class="btn btn-success rounded-pill py-3 px-5 mt-2" href=""
            >Read More</a
          >
        </div>
      </div>
    </div>
  </div>
  <!-- About End -->

  <!-- Services Start -->
  <div class="container-fluid bg-light service py-5">
    <div class="container py-5">
      <div class="mx-auto text-center mb-5" style="max-width: 900px">
        <h5 class="section-title px-3">Searvices</h5>
        <h1 class="mb-0">Our Services</h1>
      </div>
      <div class="row g-4">
        <div class="col-lg-6">
          <div class="row g-4">
            <div class="col-12">
              <div
                class="service-content-inner d-flex align-items-center bg-white border border-success rounded p-4 pe-0"
              >
                <div class="service-content text-end">
                  <h5 class="mb-4">Themed Tours</h5>
                  <p class="mb-0">
                    Explore destinations through a specific lens, like cultural immersion, historical exploration, culinary adventures, or wildlife encounters.
                  </p>
                </div>
                <div class="service-icon p-4">
                  <i class="fa fa-globe fa-4x text-success"></i>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div
                class="service-content-inner d-flex align-items-center bg-white border border-success rounded p-4 pe-0"
              >
                <div class="service-content text-end">
                  <h5 class="mb-4">Hotel Reservation</h5>
                  <p class="mb-0">
                    Dolor sit amet consectetur adipisicing elit. Non alias eum,
                    suscipit expedita corrupti officiis debitis possimus nam
                    laudantium beatae quidem dolore consequuntur voluptate rem
                    reiciendis, omnis sequi harum earum.
                  </p>
                </div>
                <div class="service-icon p-4">
                  <i class="fa fa-hotel fa-4x text-success"></i>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div
                class="service-content-inner d-flex align-items-center bg-white border border-success rounded p-4 pe-0"
              >
                <div class="service-content text-end">
                  <h5 class="mb-4">Small Group Tours</h5>
                  <p class="mb-0">
                    Experience a more intimate and personalized journey with a limited number of participants.
                  </p>
                </div>
                <div class="service-icon p-4">
                  <i class="fa fa-user fa-4x text-success"></i>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div
                class="service-content-inner d-flex align-items-center bg-white border border-success rounded p-4 pe-0"
              >
                <div class="service-content text-end">
                  <h5 class="mb-4">Visa Assistance</h5>
                  <p class="mb-0">
                    Let us handle the complexities of obtaining travel visas.
                  </p>
                </div>
                <div class="service-icon p-4">
                  <i class="fa fa-cog fa-4x text-success"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row g-4">
            <div class="col-12">
              <div
                class="service-content-inner d-flex align-items-center bg-white border border-success rounded p-4 ps-0"
              >
                <div class="service-icon p-4">
                  <i class="fa fa-globe fa-4x text-success"></i>
                </div>
                <div class="service-content">
                  <h5 class="mb-4">Travel Insurance</h5>
                  <p class="mb-0">
                    We can assist you in securing travel insurance for added peace of mind.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div
                class="service-content-inner d-flex align-items-center bg-white border border-success rounded p-4 ps-0"
              >
                <div class="service-icon p-4">
                  <i class="fa fa-hotel fa-4x text-success"></i>
                </div>
                <div class="service-content">
                  <h5 class="mb-4">Transportation</h5>
                  <p class="mb-0">
                    Enjoy comfortable and reliable transportation arrangements, including private drivers, local trains, or scenic cruises (depending on your itinerary).
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div
                class="service-content-inner d-flex align-items-center bg-white border border-success rounded p-4 ps-0"
              >
                <div class="service-icon p-4">
                  <i class="fa fa-user fa-4x text-success"></i>
                </div>
                <div class="service-content">
                  <h5 class="mb-4">Travel Guides</h5>
                  <p class="mb-0">
                    We prioritize eco-friendly tourism initiatives that minimize environmental impact.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div
                class="service-content-inner d-flex align-items-center bg-white border border-success rounded p-4 ps-0"
              >
                <div class="service-icon p-4">
                  <i class="fa fa-cog fa-4x text-success"></i>
                </div>
                <div class="service-content">
                  <h5 class="mb-4">Supporting Local Communities</h5>
                  <p class="mb-0">
                    We partner with local businesses and guides, contributing to the economic well-being of the destinations you visit.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="text-center">
            <a class="btn btn-success rounded-pill py-3 px-5 mt-2" href=""
              >Service More</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Services End -->

  <!-- Destination Start -->
  <div class="container-fluid destination py-5">
    <div class="container py-5">
      <div class="mx-auto text-center mb-5" style="max-width: 900px">
        <h5 class="section-title px-3">Destination</h5>
        <h1 class="mb-0">Popular Destination</h1>
      </div>
      <div class="tab-class text-center">
        <ul class="nav nav-pills d-inline-flex justify-content-center mb-5">
          <li class="nav-item">
            <a
              class="d-flex mx-3 py-2 border border-success bg-light rounded-pill active"
              data-bs-toggle="pill"
              href="#tab-1"
            >
              <span class="text-dark" style="width: 150px">All</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="d-flex py-2 mx-3 border border-success bg-light rounded-pill"
              data-bs-toggle="pill"
              href="#tab-2"
            >
              <span class="text-dark" style="width: 150px">Akagera</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="d-flex mx-3 py-2 border border-success bg-light rounded-pill"
              data-bs-toggle="pill"
              href="#tab-3"
            >
              <span class="text-dark" style="width: 150px">Nyungwe</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="d-flex mx-3 py-2 border border-success bg-light rounded-pill"
              data-bs-toggle="pill"
              href="#tab-4"
            >
              <span class="text-dark" style="width: 150px">Kicu Lakes</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="d-flex mx-3 py-2 border border-success bg-light rounded-pill"
              data-bs-toggle="pill"
              href="#tab-5"
            >
              <span class="text-dark" style="width: 150px"
                >Mountain Gorilla</span
              >
            </a>
          </li>
          <li class="nav-item">
            <a
              class="d-flex mx-3 py-2 border border-success bg-light rounded-pill"
              data-bs-toggle="pill"
              href="#tab-6"
            >
              <span class="text-dark" style="width: 150px">Kigali City</span>
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div id="tab-1" class="tab-pane fade show p-0 active">
            <div class="row g-4">
              <div class="col-xl-8">
                <div class="row g-4">
                  <div class="col-lg-6">
                    <div class="destination-img">
                      <img
                        class="img-fluid rounded w-100"
                        src="img/rwanda1.jpeg"
                        alt=""
                      />
                      <div class="destination-overlay p-4">
                        <h4 class="text-white mb-2 mt-3">Beautiful Rwanda</h4>
                        <a href="#" class="btn-hover text-white"
                          >View All Place <i class="fa fa-arrow-right ms-2"></i
                        ></a>
                      </div>
                      <div class="search-icon">
                        <a href="img/rwanda1.jpeg" data-lightbox="destination-1"
                          ><i
                            class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                          ></i
                        ></a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="destination-img">
                      <img
                        class="img-fluid rounded w-100"
                        src="img/lake-kivu-shores.jpeg"
                        alt=""
                      />
                      <div class="destination-overlay p-4">
                        <h4 class="text-white mb-2 mt-3">Lake Kivu Beach</h4>
                        <a href="#" class="btn-hover text-white"
                          >View All Place <i class="fa fa-arrow-right ms-2"></i
                        ></a>
                      </div>
                      <div class="search-icon">
                        <a
                          href="img/lake-kivu-shores.jpeg"
                          data-lightbox="destination-2"
                          ><i
                            class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                          ></i
                        ></a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="destination-img">
                      <img
                        class="img-fluid rounded w-100"
                        src="img/kinigi.jpeg"
                        alt=""
                      />
                      <div class="destination-overlay p-4">
                        <h4 class="text-white mb-2 mt-3">
                          Kinigi Mountain Gorilla
                        </h4>
                        <a href="#" class="btn-hover text-white"
                          >View All Place <i class="fa fa-arrow-right ms-2"></i
                        ></a>
                      </div>
                      <div class="search-icon">
                        <a href="img/kinigi.jpeg" data-lightbox="destination-7"
                          ><i
                            class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                          ></i
                        ></a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="destination-img">
                      <img
                        class="img-fluid rounded w-100"
                        src="img/Mountain-gorilla-lighter-900x600-1.jpeg"
                        alt=""
                      />
                      <div class="destination-overlay p-4">
                        <h4 class="text-white mb-2 mt-3">Mountain Gorilla</h4>
                        <a href="#" class="btn-hover text-white"
                          >View All Place <i class="fa fa-arrow-right ms-2"></i
                        ></a>
                      </div>
                      <div class="search-icon">
                        <a
                          href="img/Mountain-gorilla-lighter-900x600-1.jpeg"
                          data-lightbox="destination-8"
                          ><i
                            class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                          ></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4">
                <div class="destination-img h-100">
                  <img
                    class="img-fluid rounded w-100 h-100"
                    src="img/muhazi-beach-hotel.jpeg"
                    style="object-fit: cover; min-height: 300px"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">Muhazi Beach Hotel</h4>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/muhazi-beach-hotel.jpeg"
                      data-lightbox="destination-4"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/rwanda-mountain.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">Rwanda Mountain</h4>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/rwanda-mountain.jpeg"
                      data-lightbox="destination-4"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/wildlife-in-akagera.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">Akagera Parc</h4>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/wildlife-in-akagera.jpeg"
                      data-lightbox="destination-5"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/muhabura.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">Muhabura</h4>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                  <div class="search-icon">
                    <a href="img/muhabura.jpeg" data-lightbox="destination-6"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="tab-2" class="tab-pane fade show p-0">
            <div class="row g-4">
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/Akagera-Hippos.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">Akagera</h4>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/Akagera-Hippos.jpeg"
                      data-lightbox="destination-5"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/Parc_National_d'Akagera.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">National Park Akagera</h4>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/Parc_National_d'Akagera.jpeg"
                      data-lightbox="destination-6"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/murchison-falls-savanna-safari13-1024x683.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">National Park Akagera</h4>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/murchison-falls-savanna-safari13-1024x683.jpeg"
                      data-lightbox="destination-6"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/akagera-national-park-zebras-rwanda.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">National Park Akagera</h4>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/akagera-national-park-zebras-rwanda.jpeg"
                      data-lightbox="destination-6"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="tab-3" class="tab-pane fade show p-0">
            <div class="row g-4">
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/Canopy-Walk-Nyungwe-1024x683.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">Canopy Nyungwe</h4>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/Canopy-Walk-Nyungwe-1024x683.jpeg"
                      data-lightbox="destination-5"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/Activities-in-Nyungwe-Forest-National-Park (1).jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">
                      Nyungwe Activities Park
                    </h4>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/Activities-in-Nyungwe-Forest-National-Park (1).jpeg"
                      data-lightbox="destination-6"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/thumb_850_128_oo_nyungwehouse_exterior_accommodation_block_view_1527_master.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">
                      Nyungwe Activities Park
                    </h4>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/thumb_850_128_oo_nyungwehouse_exterior_accommodation_block_view_1527_master.jpeg"
                      data-lightbox="destination-6"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/Cyinzobe-trail-in-Nyungwe-Forest.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">
                      Nyungwe Activities Park
                    </h4>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/Cyinzobe-trail-in-Nyungwe-Forest.jpeg"
                      data-lightbox="destination-6"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="tab-4" class="tab-pane fade show p-0">
            <div class="row g-4">
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/lake-kivu.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">Lake Kivu</h4>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                  <div class="search-icon">
                    <a href="img/lake-kivu.jpeg" data-lightbox="destination-5"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/lake-kivu-shores.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">Kivu Lakes & Mountain</h4>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/lake-kivu-shores.jpeg"
                      data-lightbox="destination-6"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="tab-5" class="tab-pane fade show p-0">
            <div class="row g-4">
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/Mountain-gorilla-lighter-900x600-1.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">Mountain Gorilla</h4>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/Mountain-gorilla-lighter-900x600-1.jpeg"
                      data-lightbox="destination-5"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/960x0.webp"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">Kivu Lakes & Mountain</h4>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/960x0.webp"
                      data-lightbox="destination-6"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/GORILLAS-YT-2.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">Kivu Lakes & Mountain</h4>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/GORILLAS-YT-2.jpeg"
                      data-lightbox="destination-6"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/gorilla.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">Gorilla</h4>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                  <div class="search-icon">
                    <a href="img/gorilla.jpeg" data-lightbox="destination-6"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="tab-6" class="tab-pane fade show p-0">
            <div class="row g-4">
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/kigali-city-tour.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">City Tour</h4>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/kigali-city-tour.jpeg"
                      data-lightbox="destination-5"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/rwanda_.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">Rwanda</h4>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                  <div class="search-icon">
                    <a href="img/rwanda_.jpeg" data-lightbox="destination-6"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Destination End -->

  <!-- Explore Tour Start -->
  <div class="container-fluid ExploreTour py-5">
    <div class="container py-5">
      <div class="mx-auto text-center mb-5" style="max-width: 900px">
        <h5 class="section-title px-3">Explore Tour</h5>
        <h1 class="mb-4">The World</h1>
        <p class="mb-0">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum
          tempore nam, architecto doloremque velit explicabo? Voluptate sunt
          eveniet fuga eligendi! Expedita laudantium fugiat corrupti eum cum
          repellat a laborum quasi.
        </p>
      </div>
      <div class="tab-class text-center">
        <ul class="nav nav-pills d-inline-flex justify-content-center mb-5">
          <li class="nav-item">
            <a
              class="d-flex mx-3 py-2 border border-success bg-light rounded-pill active"
              data-bs-toggle="pill"
              href="#NationalTab-1"
            >
              <span class="text-dark" style="width: 250px"
                >National Tour Category</span
              >
            </a>
          </li>
          <li class="nav-item">
            <a
              class="d-flex py-2 mx-3 border border-success bg-light rounded-pill"
              data-bs-toggle="pill"
              href="#InternationalTab-2"
            >
              <span class="text-dark" style="width: 250px"
                >International tour Category</span
              >
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div id="NationalTab-1" class="tab-pane fade show p-0 active">
            <div class="row g-4">
              <div class="col-md-6 col-lg-4">
                <div class="national-item">
                  <img
                    src="img/explore-tour-1.jpg"
                    class="img-fluid w-100 rounded"
                    alt="Image"
                  />
                  <div class="national-content">
                    <div class="national-info">
                      <h5 class="text-white text-uppercase mb-2">
                        Weekend Tour
                      </h5>
                      <a href="#" class="btn-hover text-white"
                        >View All Place <i class="fa fa-arrow-right ms-2"></i
                      ></a>
                    </div>
                  </div>
                  <div class="national-plus-icon">
                    <a href="#" class="my-auto"
                      ><i class="fas fa-link fa-2x text-white"></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="national-item">
                  <img
                    src="img/S_176219.jpeg"
                    class="img-fluid w-100 rounded"
                    alt="Image"
                  />
                  <div class="national-content">
                    <div class="national-info">
                      <h5 class="text-white text-uppercase mb-2">
                        Holiday Tour
                      </h5>
                      <a href="#" class="btn-hover text-white"
                        >View All Place <i class="fa fa-arrow-right ms-2"></i
                      ></a>
                    </div>
                  </div>
                  <div class="national-plus-icon">
                    <a href="#" class="my-auto"
                      ><i class="fas fa-link fa-2x text-white"></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="national-item">
                  <img
                    src="img/The-course-.jpeg"
                    class="img-fluid w-100 rounded"
                    alt="Image"
                  />
                  <div class="national-content">
                    <div class="national-info">
                      <h5 class="text-white text-uppercase mb-2">Road Trip</h5>
                      <a href="#" class="btn-hover text-white"
                        >View All Place <i class="fa fa-arrow-right ms-2"></i
                      ></a>
                    </div>
                  </div>
                  <div class="tour-offer bg-info">15% Off</div>
                  <div class="national-plus-icon">
                    <a href="#" class="my-auto"
                      ><i class="fas fa-link fa-2x text-white"></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="national-item">
                  <img
                    src="img/RWANDA-MUSEUMS-2-scaled.jpeg"
                    class="img-fluid w-100 rounded"
                    alt="Image"
                  />
                  <div class="national-content">
                    <div class="national-info">
                      <h5 class="text-white text-uppercase mb-2">
                        Historical Trip
                      </h5>
                      <a href="#" class="btn-hover text-white"
                        >View All Place <i class="fa fa-arrow-right ms-2"></i
                      ></a>
                    </div>
                  </div>
                  <div class="national-plus-icon">
                    <a href="#" class="my-auto"
                      ><i class="fas fa-link fa-2x text-white"></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="national-item">
                  <img
                    src="img/Family-Game-Drives-Rwanda.jpeg"
                    class="img-fluid w-100 rounded"
                    alt="Image"
                  />
                  <div class="national-content">
                    <div class="national-info">
                      <h5 class="text-white text-uppercase mb-2">
                        Family Tour
                      </h5>
                      <a href="#" class="btn-hover text-white"
                        >View All Place <i class="fa fa-arrow-right ms-2"></i
                      ></a>
                    </div>
                  </div>
                  <div class="tour-offer bg-warning">50% Off</div>
                  <div class="national-plus-icon">
                    <a href="#" class="my-auto"
                      ><i class="fas fa-link fa-2x text-white"></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="national-item">
                  <img
                    src="img/hqdefault.jpeg"
                    class="img-fluid w-100 rounded"
                    alt="Image"
                  />
                  <div class="national-content">
                    <div class="national-info">
                      <h5 class="text-white text-uppercase mb-2">Beach Tour</h5>
                      <a href="#" class="btn-hover text-white"
                        >View All Place <i class="fa fa-arrow-right ms-2"></i
                      ></a>
                    </div>
                  </div>
                  <div class="national-plus-icon">
                    <a href="#" class="my-auto"
                      ><i class="fas fa-link fa-2x text-white"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="InternationalTab-2" class="tab-pane fade show p-0">
            <div class="InternationalTour-carousel owl-carousel">
              <div class="international-item">
                <img
                  src="img/explore-tour-1.jpg"
                  class="img-fluid w-100 rounded"
                  alt="Image"
                />
                <div class="international-content">
                  <div class="international-info">
                    <h5 class="text-white text-uppercase mb-2">Australia</h5>
                    <a href="#" class="btn-hover text-white me-4"
                      ><i class="fas fa-map-marker-alt me-1"></i> 8 Cities</a
                    >
                    <a href="#" class="btn-hover text-white"
                      ><i class="fa fa-eye ms-2"></i>
                      <span>143+ Tour Places</span></a
                    >
                  </div>
                </div>
                <div class="tour-offer bg-success">30% Off</div>
                <div class="international-plus-icon">
                  <a href="#" class="my-auto"
                    ><i class="fas fa-link fa-2x text-white"></i
                  ></a>
                </div>
              </div>
              <div class="international-item">
                <img
                  src="img/explore-tour-2.jpg"
                  class="img-fluid w-100 rounded"
                  alt="Image"
                />
                <div class="international-content">
                  <div class="international-info">
                    <h5 class="text-white text-uppercase mb-2">Germany</h5>
                    <a href="#" class="btn-hover text-white me-4"
                      ><i class="fas fa-map-marker-alt me-1"></i> 12 Cities</a
                    >
                    <a href="#" class="btn-hover text-white"
                      ><i class="fa fa-eye ms-2"></i>
                      <span>21+ Tour Places</span></a
                    >
                  </div>
                </div>
                <div class="international-plus-icon">
                  <a href="#" class="my-auto"
                    ><i class="fas fa-link fa-2x text-white"></i
                  ></a>
                </div>
              </div>
              <div class="international-item">
                <img
                  src="img/explore-tour-3.jpg"
                  class="img-fluid w-100 rounded"
                  alt="Image"
                />
                <div class="international-content">
                  <div class="tour-offer bg-warning">45% Off</div>
                  <div class="international-info">
                    <h5 class="text-white text-uppercase mb-2">Spain</h5>
                    <a href="#" class="btn-hover text-white me-4"
                      ><i class="fas fa-map-marker-alt me-1"></i> 9 Cities</a
                    >
                    <a href="#" class="btn-hover text-white"
                      ><i class="fa fa-eye ms-2"></i>
                      <span>133+ Tour Places</span></a
                    >
                  </div>
                </div>
                <div class="international-plus-icon">
                  <a href="#" class="my-auto"
                    ><i class="fas fa-link fa-2x text-white"></i
                  ></a>
                </div>
              </div>
              <div class="international-item">
                <img
                  src="img/explore-tour-4.jpg"
                  class="img-fluid w-100 rounded"
                  alt="Image"
                />
                <div class="international-content">
                  <div class="international-info">
                    <h5 class="text-white text-uppercase mb-2">Japan</h5>
                    <a href="#" class="btn-hover text-white me-4"
                      ><i class="fas fa-map-marker-alt me-1"></i> 8 Cities</a
                    >
                    <a href="#" class="btn-hover text-white"
                      ><i class="fa fa-eye ms-2"></i>
                      <span>137+ Tour Places</span></a
                    >
                  </div>
                </div>
                <div class="international-plus-icon">
                  <a href="#" class="my-auto"
                    ><i class="fas fa-link fa-2x text-white"></i
                  ></a>
                </div>
              </div>
              <div class="international-item">
                <img
                  src="img/explore-tour-5.jpg"
                  class="img-fluid w-100 rounded"
                  alt="Image"
                />
                <div class="international-content">
                  <div class="tour-offer bg-info">70% Off</div>
                  <div class="international-info">
                    <h5 class="text-white text-uppercase mb-2">London</h5>
                    <a href="#" class="btn-hover text-white me-4"
                      ><i class="fas fa-map-marker-alt me-1"></i> 17 Cities</a
                    >
                    <a href="#" class="btn-hover text-white"
                      ><i class="fa fa-eye ms-2"></i>
                      <span>26+ Tour Places</span></a
                    >
                  </div>
                </div>
                <div class="international-plus-icon">
                  <a href="#" class="my-auto"
                    ><i class="fas fa-link fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Explore Tour Start -->

  <!-- Packages Start -->
  <div class="container-fluid packages py-5">
    <div class="container py-5">
      <div class="mx-auto text-center mb-5" style="max-width: 900px">
        <h5 class="section-title px-3">Packages</h5>
        <h1 class="mb-0">Awesome Packages</h1>
      </div>
      <div class="packages-carousel owl-carousel">
        <div class="packages-item">
          <div class="packages-img">
            <img
              src="img/packages-4.jpg"
              class="img-fluid w-100 rounded-top"
              alt="Image"
            />
            <div
              class="packages-info d-flex border border-start-0 border-end-0 position-absolute"
              style="width: 100%; bottom: 0; left: 0; z-index: 5"
            >
              <small class="flex-fill text-center border-end py-2"
                ><i class="fa fa-map-marker-alt me-2"></i>Venice - Italy</small
              >
              <small class="flex-fill text-center border-end py-2"
                ><i class="fa fa-calendar-alt me-2"></i>3 days</small
              >
              <small class="flex-fill text-center py-2"
                ><i class="fa fa-user me-2"></i>2 Person</small
              >
            </div>
            <div class="packages-price py-2 px-4">$349.00</div>
          </div>
          <div class="packages-content bg-light">
            <div class="p-4 pb-0">
              <h5 class="mb-0">Venice - Italy</h5>
              <small class="text-uppercase">Hotel Deals</small>
              <div class="mb-3">
                <small class="fa fa-star text-success"></small>
                <small class="fa fa-star text-success"></small>
                <small class="fa fa-star text-success"></small>
                <small class="fa fa-star text-success"></small>
                <small class="fa fa-star text-success"></small>
              </div>
              <p class="mb-4">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Nesciunt nemo quia quae illum aperiam fugiat voluptatem repellat
              </p>
            </div>
            <div class="row bg-success rounded-bottom mx-0">
              <div class="col-6 text-start px-0">
                <a href="#" class="btn-hover btn text-white py-2 px-4"
                  >Read More</a
                >
              </div>
              <div class="col-6 text-end px-0">
                <a href="#" class="btn-hover btn text-white py-2 px-4"
                  >Book Now</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="packages-item">
          <div class="packages-img">
            <img
              src="img/packages-2.jpg"
              class="img-fluid w-100 rounded-top"
              alt="Image"
            />
            <div
              class="packages-info d-flex border border-start-0 border-end-0 position-absolute"
              style="width: 100%; bottom: 0; left: 0; z-index: 5"
            >
              <small class="flex-fill text-center border-end py-2"
                ><i class="fa fa-map-marker-alt me-2"></i>Venice - Italy</small
              >
              <small class="flex-fill text-center border-end py-2"
                ><i class="fa fa-calendar-alt me-2"></i>3 days</small
              >
              <small class="flex-fill text-center py-2"
                ><i class="fa fa-user me-2"></i>2 Person</small
              >
            </div>
            <div class="packages-price py-2 px-4">$449.00</div>
          </div>
          <div class="packages-content bg-light">
            <div class="p-4 pb-0">
              <h5 class="mb-0">The New California</h5>
              <small class="text-uppercase">Hotel Deals</small>
              <div class="mb-3">
                <small class="fa fa-star text-success"></small>
                <small class="fa fa-star text-success"></small>
                <small class="fa fa-star text-success"></small>
                <small class="fa fa-star text-success"></small>
                <small class="fa fa-star text-success"></small>
              </div>
              <p class="mb-4">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Nesciunt nemo quia quae illum aperiam fugiat voluptatem repellat
              </p>
            </div>
            <div class="row bg-success rounded-bottom mx-0">
              <div class="col-6 text-start px-0">
                <a href="#" class="btn-hover btn text-white py-2 px-4"
                  >Read More</a
                >
              </div>
              <div class="col-6 text-end px-0">
                <a href="#" class="btn-hover btn text-white py-2 px-4"
                  >Book Now</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="packages-item">
          <div class="packages-img">
            <img
              src="img/packages-3.jpg"
              class="img-fluid w-100 rounded-top"
              alt="Image"
            />
            <div
              class="packages-info d-flex border border-start-0 border-end-0 position-absolute"
              style="width: 100%; bottom: 0; left: 0; z-index: 5"
            >
              <small class="flex-fill text-center border-end py-2"
                ><i class="fa fa-map-marker-alt me-2"></i>Venice - Italy</small
              >
              <small class="flex-fill text-center border-end py-2"
                ><i class="fa fa-calendar-alt me-2"></i>3 days</small
              >
              <small class="flex-fill text-center py-2"
                ><i class="fa fa-user me-2"></i>2 Person</small
              >
            </div>
            <div class="packages-price py-2 px-4">$549.00</div>
          </div>
          <div class="packages-content bg-light">
            <div class="p-4 pb-0">
              <h5 class="mb-0">Discover Japan</h5>
              <small class="text-uppercase">Hotel Deals</small>
              <div class="mb-3">
                <small class="fa fa-star text-success"></small>
                <small class="fa fa-star text-success"></small>
                <small class="fa fa-star text-success"></small>
                <small class="fa fa-star text-success"></small>
                <small class="fa fa-star text-success"></small>
              </div>
              <p class="mb-4">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Nesciunt nemo quia quae illum aperiam fugiat voluptatem repellat
              </p>
            </div>
            <div class="row bg-success rounded-bottom mx-0">
              <div class="col-6 text-start px-0">
                <a href="#" class="btn-hover btn text-white py-2 px-4"
                  >Read More</a
                >
              </div>
              <div class="col-6 text-end px-0">
                <a href="#" class="btn-hover btn text-white py-2 px-4"
                  >Book Now</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="packages-item">
          <div class="packages-img">
            <img
              src="img/packages-1.jpg"
              class="img-fluid w-100 rounded-top"
              alt="Image"
            />
            <div
              class="packages-info d-flex border border-start-0 border-end-0 position-absolute"
              style="width: 100%; bottom: 0; left: 0; z-index: 5"
            >
              <small class="flex-fill text-center border-end py-2"
                ><i class="fa fa-map-marker-alt me-2"></i>Thayland</small
              >
              <small class="flex-fill text-center border-end py-2"
                ><i class="fa fa-calendar-alt me-2"></i>3 days</small
              >
              <small class="flex-fill text-center py-2"
                ><i class="fa fa-user me-2"></i>2 Person</small
              >
            </div>
            <div class="packages-price py-2 px-4">$649.00</div>
          </div>
          <div class="packages-content bg-light">
            <div class="p-4 pb-0">
              <h5 class="mb-0">Thayland Trip</h5>
              <small class="text-uppercase">Hotel Deals</small>
              <div class="mb-3">
                <small class="fa fa-star text-success"></small>
                <small class="fa fa-star text-success"></small>
                <small class="fa fa-star text-success"></small>
                <small class="fa fa-star text-success"></small>
                <small class="fa fa-star text-success"></small>
              </div>
              <p class="mb-4">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Nesciunt nemo quia quae illum aperiam fugiat voluptatem repellat
              </p>
            </div>
            <div class="row bg-success rounded-bottom mx-0">
              <div class="col-6 text-start px-0">
                <a href="#" class="btn-hover btn text-white py-2 px-4"
                  >Read More</a
                >
              </div>
              <div class="col-6 text-end px-0">
                <a href="#" class="btn-hover btn text-white py-2 px-4"
                  >Book Now</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Packages End -->

  <!-- Gallery Start -->
  <div class="container-fluid gallery py-5 my-5">
    <div class="mx-auto text-center mb-5" style="max-width: 900px">
      <h5 class="section-title px-3">Our Gallery</h5>
      <h1 class="mb-4">Tourism & Traveling Gallery.</h1>
      <p class="mb-0">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum tempore
        nam, architecto doloremque velit explicabo? Voluptate sunt eveniet fuga
        eligendi! Expedita laudantium fugiat corrupti eum cum repellat a laborum
        quasi.
      </p>
    </div>
    <div class="tab-class text-center">
      <ul class="nav nav-pills d-inline-flex justify-content-center mb-5">
        <li class="nav-item">
          <a
            class="d-flex mx-3 py-2 border border-success bg-light rounded-pill active"
            data-bs-toggle="pill"
            href="#GalleryTab-1"
          >
            <span class="text-dark" style="width: 150px">All</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="d-flex py-2 mx-3 border border-success bg-light rounded-pill"
            data-bs-toggle="pill"
            href="#GalleryTab-2"
          >
            <span class="text-dark" style="width: 150px">World tour</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="d-flex mx-3 py-2 border border-success bg-light rounded-pill"
            data-bs-toggle="pill"
            href="#GalleryTab-3"
          >
            <span class="text-dark" style="width: 150px">Ocean Tour</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="d-flex mx-3 py-2 border border-success bg-light rounded-pill"
            data-bs-toggle="pill"
            href="#GalleryTab-4"
          >
            <span class="text-dark" style="width: 150px">Summer Tour</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="d-flex mx-3 py-2 border border-success bg-light rounded-pill"
            data-bs-toggle="pill"
            href="#GalleryTab-5"
          >
            <span class="text-dark" style="width: 150px">Sport Tour</span>
          </a>
        </li>
      </ul>
      <div class="tab-content">
        <div id="GalleryTab-1" class="tab-pane fade show p-0 active">
          <div class="row g-2">
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
              <div class="gallery-item h-100">
                <img
                  src="img/gallery-1.jpg"
                  class="img-fluid w-100 h-100 rounded"
                  alt="Image"
                />
                <div class="gallery-content">
                  <div class="gallery-info">
                    <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                </div>
                <div class="gallery-plus-icon">
                  <a
                    href="img/gallery-1.jpg"
                    data-lightbox="gallery-1"
                    class="my-auto"
                    ><i class="fas fa-plus fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
              <div class="gallery-item h-100">
                <img
                  src="img/gallery-2.jpg"
                  class="img-fluid w-100 h-100 rounded"
                  alt="Image"
                />
                <div class="gallery-content">
                  <div class="gallery-info">
                    <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                </div>
                <div class="gallery-plus-icon">
                  <a
                    href="img/gallery-2.jpg"
                    data-lightbox="gallery-2"
                    class="my-auto"
                    ><i class="fas fa-plus fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
              <div class="gallery-item h-100">
                <img
                  src="img/gallery-3.jpg"
                  class="img-fluid w-100 h-100 rounded"
                  alt="Image"
                />
                <div class="gallery-content">
                  <div class="gallery-info">
                    <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                </div>
                <div class="gallery-plus-icon">
                  <a
                    href="img/gallery-3.jpg"
                    data-lightbox="gallery-3"
                    class="my-auto"
                    ><i class="fas fa-plus fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
              <div class="gallery-item h-100">
                <img
                  src="img/gallery-4.jpg"
                  class="img-fluid w-100 h-100 rounded"
                  alt="Image"
                />
                <div class="gallery-content">
                  <div class="gallery-info">
                    <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                </div>
                <div class="gallery-plus-icon">
                  <a
                    href="img/gallery-4.jpg"
                    data-lightbox="gallery-4"
                    class="my-auto"
                    ><i class="fas fa-plus fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
              <div class="gallery-item h-100">
                <img
                  src="img/gallery-5.jpg"
                  class="img-fluid w-100 h-100 rounded"
                  alt="Image"
                />
                <div class="gallery-content">
                  <div class="gallery-info">
                    <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                </div>
                <div class="gallery-plus-icon">
                  <a
                    href="img/gallery-5.jpg"
                    data-lightbox="gallery-5"
                    class="my-auto"
                    ><i class="fas fa-plus fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
              <div class="gallery-item h-100">
                <img
                  src="img/gallery-6.jpg"
                  class="img-fluid w-100 h-100 rounded"
                  alt="Image"
                />
                <div class="gallery-content">
                  <div class="gallery-info">
                    <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                </div>
                <div class="gallery-plus-icon">
                  <a
                    href="img/gallery-6.jpg"
                    data-lightbox="gallery-6"
                    class="my-auto"
                    ><i class="fas fa-plus fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
              <div class="gallery-item h-100">
                <img
                  src="img/gallery-7.jpg"
                  class="img-fluid w-100 h-100 rounded"
                  alt="Image"
                />
                <div class="gallery-content">
                  <div class="gallery-info">
                    <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                </div>
                <div class="gallery-plus-icon">
                  <a
                    href="img/gallery-7.jpg"
                    data-lightbox="gallery-7"
                    class="my-auto"
                    ><i class="fas fa-plus fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-3 col-xl-2">
              <div class="gallery-item h-100">
                <img
                  src="img/gallery-8.jpg"
                  class="img-fluid w-100 h-100 rounded"
                  alt="Image"
                />
                <div class="gallery-content">
                  <div class="gallery-info">
                    <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                </div>
                <div class="gallery-plus-icon">
                  <a
                    href="img/gallery-8.jpg"
                    data-lightbox="gallery-8"
                    class="my-auto"
                    ><i class="fas fa-plus fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
              <div class="gallery-item h-100">
                <img
                  src="img/gallery-9.jpg"
                  class="img-fluid w-100 h-100 rounded"
                  alt="Image"
                />
                <div class="gallery-content">
                  <div class="gallery-info">
                    <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                </div>
                <div class="gallery-plus-icon">
                  <a
                    href="img/gallery-9.jpg"
                    data-lightbox="gallery-9"
                    class="my-auto"
                    ><i class="fas fa-plus fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-3 col-xl-2">
              <div class="gallery-item h-100">
                <img
                  src="img/gallery-10.jpg"
                  class="img-fluid w-100 h-100 rounded"
                  alt="Image"
                />
                <div class="gallery-content">
                  <div class="gallery-info">
                    <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                </div>
                <div class="gallery-plus-icon">
                  <a
                    href="img/gallery-10.jpg"
                    data-lightbox="gallery-10"
                    class="my-auto"
                    ><i class="fas fa-plus fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="GalleryTab-2" class="tab-pane fade show p-0">
          <div class="row g-2">
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
              <div class="gallery-item h-100">
                <img
                  src="img/gallery-2.jpg"
                  class="img-fluid w-100 h-100 rounded"
                  alt="Image"
                />
                <div class="gallery-content">
                  <div class="gallery-info">
                    <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                </div>
                <div class="gallery-plus-icon">
                  <a
                    href="img/gallery-2.jpg"
                    data-lightbox="gallery-2"
                    class="my-auto"
                    ><i class="fas fa-plus fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
              <div class="gallery-item h-100">
                <img
                  src="img/gallery-3.jpg"
                  class="img-fluid w-100 h-100 rounded"
                  alt="Image"
                />
                <div class="gallery-content">
                  <div class="gallery-info">
                    <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                </div>
                <div class="gallery-plus-icon">
                  <a
                    href="img/gallery-3.jpg"
                    data-lightbox="gallery-3"
                    class="my-auto"
                    ><i class="fas fa-plus fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="GalleryTab-3" class="tab-pane fade show p-0">
          <div class="row g-2">
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
              <div class="gallery-item h-100">
                <img
                  src="img/gallery-2.jpg"
                  class="img-fluid w-100 h-100 rounded"
                  alt="Image"
                />
                <div class="gallery-content">
                  <div class="gallery-info">
                    <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                </div>
                <div class="gallery-plus-icon">
                  <a
                    href="img/gallery-2.jpg"
                    data-lightbox="gallery-2"
                    class="my-auto"
                    ><i class="fas fa-plus fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
              <div class="gallery-item h-100">
                <img
                  src="img/gallery-3.jpg"
                  class="img-fluid w-100 h-100 rounded"
                  alt="Image"
                />
                <div class="gallery-content">
                  <div class="gallery-info">
                    <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                </div>
                <div class="gallery-plus-icon">
                  <a
                    href="img/gallery-3.jpg"
                    data-lightbox="gallery-3"
                    class="my-auto"
                    ><i class="fas fa-plus fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="GalleryTab-4" class="tab-pane fade show p-0">
          <div class="row g-2">
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
              <div class="gallery-item h-100">
                <img
                  src="img/gallery-2.jpg"
                  class="img-fluid w-100 h-100 rounded"
                  alt="Image"
                />
                <div class="gallery-content">
                  <div class="gallery-info">
                    <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                </div>
                <div class="gallery-plus-icon">
                  <a
                    href="img/gallery-2.jpg"
                    data-lightbox="gallery-2"
                    class="my-auto"
                    ><i class="fas fa-plus fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
              <div class="gallery-item h-100">
                <img
                  src="img/gallery-3.jpg"
                  class="img-fluid w-100 h-100 rounded"
                  alt="Image"
                />
                <div class="gallery-content">
                  <div class="gallery-info">
                    <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                </div>
                <div class="gallery-plus-icon">
                  <a
                    href="img/gallery-3.jpg"
                    data-lightbox="gallery-3"
                    class="my-auto"
                    ><i class="fas fa-plus fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="GalleryTab-5" class="tab-pane fade show p-0">
          <div class="row g-2">
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
              <div class="gallery-item h-100">
                <img
                  src="img/gallery-2.jpg"
                  class="img-fluid w-100 h-100 rounded"
                  alt="Image"
                />
                <div class="gallery-content">
                  <div class="gallery-info">
                    <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                </div>
                <div class="gallery-plus-icon">
                  <a
                    href="img/gallery-2.jpg"
                    data-lightbox="gallery-2"
                    class="my-auto"
                    ><i class="fas fa-plus fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
              <div class="gallery-item h-100">
                <img
                  src="img/gallery-3.jpg"
                  class="img-fluid w-100 h-100 rounded"
                  alt="Image"
                />
                <div class="gallery-content">
                  <div class="gallery-info">
                    <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                    <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                </div>
                <div class="gallery-plus-icon">
                  <a
                    href="img/gallery-3.jpg"
                    data-lightbox="gallery-3"
                    class="my-auto"
                    ><i class="fas fa-plus fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Gallery End -->

  <!-- Tour Booking Start -->
  <div class="container-fluid booking py-5">
    <div class="container py-5">
      <div class="row g-5 align-items-center">
        <div class="col-lg-6">
          <h5 class="section-booking-title pe-3">Booking</h5>
          <h1 class="text-white mb-4">Online Booking</h1>
          <p class="text-white mb-4">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur
            maxime ullam esse fuga blanditiis accusantium pariatur quis
            sapiente, veniam doloribus praesentium? Repudiandae iste voluptatem
            fugiat doloribus quasi quo iure officia.
          </p>
          <p class="text-white mb-4">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur
            maxime ullam esse fuga blanditiis accusantium pariatur quis
            sapiente, veniam doloribus praesentium? Repudiandae iste voluptatem
            fugiat doloribus quasi quo iure officia.
          </p>
          <a
            href="#"
            class="btn btn-light text-success rounded-pill py-3 px-5 mt-2"
            >Read More</a
          >
        </div>
        <div class="col-lg-6">
          <h1 class="text-white mb-3">Book A Tour Deals</h1>
          <p class="text-white mb-4">
            Get <span class="text-warning">50% Off</span> On Your First
            Adventure Trip With Travela. Get More Deal Offers Here.
          </p>
          <form>
            <div class="row g-3">
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control bg-white border-0"
                    id="name"
                    placeholder="Your Name"
                  />
                  <label for="name">Your Name</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="email"
                    class="form-control bg-white border-0"
                    id="email"
                    placeholder="Your Email"
                  />
                  <label for="email">Your Email</label>
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="form-floating date"
                  id="date3"
                  data-target-input="nearest"
                >
                  <input
                    type="text"
                    class="form-control bg-white border-0"
                    id="datetime"
                    placeholder="Date & Time"
                    data-target="#date3"
                    data-toggle="datetimepicker"
                  />
                  <label for="datetime">Date & Time</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <select class="form-select bg-white border-0" id="select1">
                    <option value="1">Destination 1</option>
                    <option value="2">Destination 2</option>
                    <option value="3">Destination 3</option>
                  </select>
                  <label for="select1">Destination</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <select
                    class="form-select bg-white border-0"
                    id="SelectPerson"
                  >
                    <option value="1">Persons 1</option>
                    <option value="2">Persons 2</option>
                    <option value="3">Persons 3</option>
                  </select>
                  <label for="SelectPerson">Persons</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <select
                    class="form-select bg-white border-0"
                    id="CategoriesSelect"
                  >
                    <option value="1">Kids</option>
                    <option value="2">1</option>
                    <option value="3">2</option>
                    <option value="3">3</option>
                  </select>
                  <label for="CategoriesSelect">Categories</label>
                </div>
              </div>
              <div class="col-12">
                <div class="form-floating">
                  <textarea
                    class="form-control bg-white border-0"
                    placeholder="Special Request"
                    id="message"
                    style="height: 100px"
                  ></textarea>
                  <label for="message">Special Request</label>
                </div>
              </div>
              <div class="col-12">
                <button
                  class="btn btn-success text-white w-100 py-3"
                  type="submit"
                >
                  Book Now
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- Tour Booking End -->

  <!-- Travel Guide Start -->
  <div class="container-fluid guide py-5">
    <div class="container py-5">
      <div class="mx-auto text-center mb-5" style="max-width: 900px">
        <h5 class="section-title px-3">Travel Guide</h5>
        <h1 class="mb-0">Meet Our Guide</h1>
      </div>
      <div class="row g-4">
        <div class="col-md-6 col-lg-3">
          <div class="guide-item">
            <div class="guide-img">
              <div class="guide-img-efects">
                <img
                  src="img/guide-1.jpg"
                  class="img-fluid w-100 rounded-top"
                  alt="Image"
                />
              </div>
              <div class="guide-icon rounded-pill p-2">
                <a
                  class="btn btn-square btn-success rounded-circle mx-1"
                  href=""
                  ><i class="fab fa-facebook-f"></i
                ></a>
                <a
                  class="btn btn-square btn-success rounded-circle mx-1"
                  href=""
                  ><i class="fab fa-twitter"></i
                ></a>
                <a
                  class="btn btn-square btn-success rounded-circle mx-1"
                  href=""
                  ><i class="fab fa-instagram"></i
                ></a>
                <a
                  class="btn btn-square btn-success rounded-circle mx-1"
                  href=""
                  ><i class="fab fa-linkedin-in"></i
                ></a>
              </div>
            </div>
            <div class="guide-title text-center rounded-bottom p-4">
              <div class="guide-title-inner">
                <h4 class="mt-3">Full Name</h4>
                <p class="mb-0">Designation</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="guide-item">
            <div class="guide-img">
              <div class="guide-img-efects">
                <img
                  src="img/guide-2.jpg"
                  class="img-fluid w-100 rounded-top"
                  alt="Image"
                />
              </div>
              <div class="guide-icon rounded-pill p-2">
                <a
                  class="btn btn-square btn-success rounded-circle mx-1"
                  href=""
                  ><i class="fab fa-facebook-f"></i
                ></a>
                <a
                  class="btn btn-square btn-success rounded-circle mx-1"
                  href=""
                  ><i class="fab fa-twitter"></i
                ></a>
                <a
                  class="btn btn-square btn-success rounded-circle mx-1"
                  href=""
                  ><i class="fab fa-instagram"></i
                ></a>
                <a
                  class="btn btn-square btn-success rounded-circle mx-1"
                  href=""
                  ><i class="fab fa-linkedin-in"></i
                ></a>
              </div>
            </div>
            <div class="guide-title text-center rounded-bottom p-4">
              <div class="guide-title-inner">
                <h4 class="mt-3">Full Name</h4>
                <p class="mb-0">Designation</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="guide-item">
            <div class="guide-img">
              <div class="guide-img-efects">
                <img
                  src="img/guide-3.jpg"
                  class="img-fluid w-100 rounded-top"
                  alt="Image"
                />
              </div>
              <div class="guide-icon rounded-pill p-2">
                <a
                  class="btn btn-square btn-success rounded-circle mx-1"
                  href=""
                  ><i class="fab fa-facebook-f"></i
                ></a>
                <a
                  class="btn btn-square btn-success rounded-circle mx-1"
                  href=""
                  ><i class="fab fa-twitter"></i
                ></a>
                <a
                  class="btn btn-square btn-success rounded-circle mx-1"
                  href=""
                  ><i class="fab fa-instagram"></i
                ></a>
                <a
                  class="btn btn-square btn-success rounded-circle mx-1"
                  href=""
                  ><i class="fab fa-linkedin-in"></i
                ></a>
              </div>
            </div>
            <div class="guide-title text-center rounded-bottom p-4">
              <div class="guide-title-inner">
                <h4 class="mt-3">Full Name</h4>
                <p class="mb-0">Designation</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="guide-item">
            <div class="guide-img">
              <div class="guide-img-efects">
                <img
                  src="img/guide-4.jpg"
                  class="img-fluid w-100 rounded-top"
                  alt="Image"
                />
              </div>
              <div class="guide-icon rounded-pill p-2">
                <a
                  class="btn btn-square btn-success rounded-circle mx-1"
                  href=""
                  ><i class="fab fa-facebook-f"></i
                ></a>
                <a
                  class="btn btn-square btn-success rounded-circle mx-1"
                  href=""
                  ><i class="fab fa-twitter"></i
                ></a>
                <a
                  class="btn btn-square btn-success rounded-circle mx-1"
                  href=""
                  ><i class="fab fa-instagram"></i
                ></a>
                <a
                  class="btn btn-square btn-success rounded-circle mx-1"
                  href=""
                  ><i class="fab fa-linkedin-in"></i
                ></a>
              </div>
            </div>
            <div class="guide-title text-center rounded-bottom p-4">
              <div class="guide-title-inner">
                <h4 class="mt-3">Full Name</h4>
                <p class="mb-0">Designation</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Travel Guide End -->

  <!-- Blog Start -->
  <div class="container-fluid blog py-5">
    <div class="container py-5">
      <div class="mx-auto text-center mb-5" style="max-width: 900px">
        <h5 class="section-title px-3">Our Blog</h5>
        <h1 class="mb-4">Popular Travel Blogs</h1>
        <p class="mb-0">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti
          deserunt tenetur sapiente atque. Magni non explicabo beatae sit, vel
          reiciendis consectetur numquam id similique sunt error obcaecati
          ducimus officia maiores.
        </p>
      </div>
      <div class="row g-4 justify-content-center">
        <div class="col-lg-4 col-md-6">
          <div class="blog-item">
            <div class="blog-img">
              <div class="blog-img-inner">
                <img
                  class="img-fluid w-100 rounded-top"
                  src="img/blog-1.jpg"
                  alt="Image"
                />
                <div class="blog-icon">
                  <a href="#" class="my-auto"
                    ><i class="fas fa-link fa-2x text-white"></i
                  ></a>
                </div>
              </div>
              <div
                class="blog-info d-flex align-items-center border border-start-0 border-end-0"
              >
                <small class="flex-fill text-center border-end py-2"
                  ><i class="fa fa-calendar-alt text-success me-2"></i>28 Jan
                  2050</small
                >
                <a
                  href="#"
                  class="btn-hover flex-fill text-center text-white border-end py-2"
                  ><i class="fa fa-thumbs-up text-success me-2"></i>1.7K</a
                >
                <a
                  href="#"
                  class="btn-hover flex-fill text-center text-white py-2"
                  ><i class="fa fa-comments text-success me-2"></i>1K</a
                >
              </div>
            </div>
            <div class="blog-content border border-top-0 rounded-bottom p-4">
              <p class="mb-3">Posted By: Royal Hamblin</p>
              <a href="#" class="h4">Adventures Trip</a>
              <p class="my-3">
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
                amet diam eos
              </p>
              <a href="#" class="btn btn-success rounded-pill py-2 px-4"
                >Read More</a
              >
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="blog-item">
            <div class="blog-img">
              <div class="blog-img-inner">
                <img
                  class="img-fluid w-100 rounded-top"
                  src="img/blog-2.jpg"
                  alt="Image"
                />
                <div class="blog-icon">
                  <a href="#" class="my-auto"
                    ><i class="fas fa-link fa-2x text-white"></i
                  ></a>
                </div>
              </div>
              <div
                class="blog-info d-flex align-items-center border border-start-0 border-end-0"
              >
                <small class="flex-fill text-center border-end py-2"
                  ><i class="fa fa-calendar-alt text-success me-2"></i>28 Jan
                  2050</small
                >
                <a
                  href="#"
                  class="btn-hover flex-fill text-center text-white border-end py-2"
                  ><i class="fa fa-thumbs-up text-success me-2"></i>1.7K</a
                >
                <a
                  href="#"
                  class="btn-hover flex-fill text-center text-white py-2"
                  ><i class="fa fa-comments text-success me-2"></i>1K</a
                >
              </div>
            </div>
            <div class="blog-content border border-top-0 rounded-bottom p-4">
              <p class="mb-3">Posted By: Royal Hamblin</p>
              <a href="#" class="h4">Adventures Trip</a>
              <p class="my-3">
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
                amet diam eos
              </p>
              <a href="#" class="btn btn-success rounded-pill py-2 px-4"
                >Read More</a
              >
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="blog-item">
            <div class="blog-img">
              <div class="blog-img-inner">
                <img
                  class="img-fluid w-100 rounded-top"
                  src="img/blog-3.jpg"
                  alt="Image"
                />
                <div class="blog-icon">
                  <a href="#" class="my-auto"
                    ><i class="fas fa-link fa-2x text-white"></i
                  ></a>
                </div>
              </div>
              <div
                class="blog-info d-flex align-items-center border border-start-0 border-end-0"
              >
                <small class="flex-fill text-center border-end py-2"
                  ><i class="fa fa-calendar-alt text-success me-2"></i>28 Jan
                  2050</small
                >
                <a
                  href="#"
                  class="btn-hover flex-fill text-center text-white border-end py-2"
                  ><i class="fa fa-thumbs-up text-success me-2"></i>1.7K</a
                >
                <a
                  href="#"
                  class="btn-hover flex-fill text-center text-white py-2"
                  ><i class="fa fa-comments text-success me-2"></i>1K</a
                >
              </div>
            </div>
            <div class="blog-content border border-top-0 rounded-bottom p-4">
              <p class="mb-3">Posted By: Royal Hamblin</p>
              <a href="#" class="h4">Adventures Trip</a>
              <p class="my-3">
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
                amet diam eos
              </p>
              <a href="#" class="btn btn-success rounded-pill py-2 px-4"
                >Read More</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Blog End -->

  <!-- Subscribe Start -->
  <div class="container-fluid subscribe py-5">
    <div class="container text-center py-5">
      <div class="mx-auto text-center" style="max-width: 900px">
        <h5 class="subscribe-title px-3">Subscribe</h5>
        <h1 class="text-white mb-4">Our Newsletter</h1>
        <p class="text-white mb-5">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum
          tempore nam, architecto doloremque velit explicabo? Voluptate sunt
          eveniet fuga eligendi! Expedita laudantium fugiat corrupti eum cum
          repellat a laborum quasi.
        </p>
        <div class="position-relative mx-auto">
          <input
            class="form-control border-success rounded-pill w-100 py-3 ps-4 pe-5"
            type="text"
            placeholder="Your email"
          />
          <button
            type="button"
            class="btn btn-success rounded-pill position-absolute top-0 end-0 py-2 px-4 mt-2 me-2"
          >
            Subscribe
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Subscribe End -->

  <!-- Footer Start -->
  <FooterItem />
  <!-- Footer End -->
</template>
<script>
import FooterItem from '../components/FooterItem.vue'
export default {
  components: {
    FooterItem
  }
}
</script>
